.header-container {
    height: 14vh;
    .logo {
        height: 60%;
    }
}

//FOR MOBILE
@media screen and (max-width: 416px) {
    .header-container {
        .logo {
            height: 50%;
        }
    }
}
