.floatButton {
  height: 50px;
  width: 50px;
    .ant-float-btn-body {
      .ant-float-btn-content {
        padding: 0px;
        width: 100%;
        height: 100%;
        .ant-float-btn-icon {
          margin: 0px;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
}
